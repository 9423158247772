<template>
  <div class="test">
    <div class="box container">
      <h2 style="text-align: center">Covsymptoms Predictor!</h2>
      <br />
      <h4>Do you have cough?</h4>
      <div class="custom-control custom-checkbox">
        <input
          type="radio"
          class="form-check-input"
          :value="true"
          v-model="has_cough"
          id="has_cough"
          name="has_cough"
        />
        <label for="has_cough">Yes</label><br />
      </div>
      <div class="custom-control custom-checkbox">
        <input
          type="radio"
          class="form-check-input"
          :value="false"
          v-model="has_cough"
          id="has_cough"
          name="has_cough"
        />
        <label for="has_cough">No</label><br />
      </div>
      <h4>Do you have fever?</h4>
      <div class="custom-control custom-checkbox">
        <input
          type="radio"
          class="form-check-input"
          :value="true"
          v-model="has_fever"
          id="has_fever"
          name="has_fever"
        />
        <label for="has_fever">Yes</label><br />
      </div>
      <div class="custom-control custom-checkbox">
        <input
          type="radio"
          class="form-check-input"
          :value="false"
          v-model="has_fever"
          id="has_fever"
          name="has_fever"
        />
        <label for="has_fever">No</label><br />
      </div>
      <h4>Do you have head ache?</h4>
      <div class="custom-control custom-checkbox">
        <input
          type="radio"
          class="form-check-input"
          :value="true"
          v-model="has_head_ache"
          id="has_head_ache"
          name="has_head_ache"
        />
        <label for="has_head_ache">Yes</label><br />
      </div>
      <div class="custom-control custom-checkbox">
        <input
          type="radio"
          class="form-check-input"
          :value="false"
          v-model="has_head_ache"
          id="has_head_ache"
          name="has_head_ache"
        />
        <label for="has_head_ache">No</label><br />
      </div>

      <h4>Do you have shortness of breath?</h4>
      <div class="custom-control custom-checkbox">
        <input
          type="radio"
          class="form-check-input"
          :value="true"
          v-model="has_shortness_of_breath"
          id="has_shortness_of_breath"
          name="has_shortness_of_breath"
        />
        <label for="has_shortness_of_breath">Yes</label><br />
      </div>
      <div class="custom-control custom-checkbox">
        <input
          type="radio"
          class="form-check-input"
          :value="false"
          v-model="has_shortness_of_breath"
          id="has_shortness_of_breath"
          name="has_shortness_of_breath"
        />
        <label for="has_shortness_of_breath">No</label><br />
      </div>

      <h4>Have you been abroad?</h4>
      <div class="custom-control custom-checkbox">
        <input
          type="radio"
          class="form-check-input"
          :value="true"
          v-model="has_been_abroad"
          id="has_been_abroad"
          name="has_been_abroad"
        />
        <label for="has_been_abroad">Yes</label><br />
      </div>
      <div class="custom-control custom-checkbox">
        <input
          type="radio"
          class="form-check-input"
          :value="false"
          v-model="has_been_abroad"
          id="has_been_abroad"
          name="has_been_abroad"
        />
        <label for="has_been_abroad">No</label><br />
      </div>

      <h4>Have you been near a covid positive person?</h4>
      <div class="custom-control custom-checkbox">
        <input
          type="radio"
          class="form-check-input"
          v-model="contact_with_confirmed"
          id="contact_with_confirmed"
          name="contact_with_confirmed"
        />
        <label for="contact_with_confirmed">Yes</label><br />
      </div>
      <div class="custom-control custom-checkbox">
        <input
          type="radio"
          class="form-check-input"
          id="contact_with_confirmed"
          name="contact_with_confirmed"
        />
        <label for="contact_with_confirmed">No</label><br />
      </div>

      <h4>Are you above 60</h4>

      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          class="form-check-input"
          v-model="above_60_yes"
          id="above_60_yes"
          name="above_60_yes"
        />
        <label for="above_60_yes">Yes</label><br />
      </div>
      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          class="form-check-input"
          v-model="above_60_no"
          id="above_60_no"
          name="above_60_no"
        />
        <label for="above_60_no">No.</label><br />
      </div>

      <h4>Are you a male or female?</h4>

      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          class="form-check-input"
          v-model="is_female"
          id="is_female"
          name="is_female"
        />
        <label for="is_female_60_no"> I am a female.</label><br />
      </div>
      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          class="form-check-input"
          v-model="is_male"
          id="is_male"
          name="is_male"
        />
        <label for="is_male"> I am a male.</label><br />
      </div>
      <br />
      <div class="sticky">
        <button @click="fetchAPI" class="btn btn-success button-h">
          Predict
        </button>
      </div>
    </div>
    <!-- <button @click="fetchAPI()">Enter</button> -->

    <div id="app">
      <div v-if="showModal">
        <transition name="modal fade">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Result</h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true" @click="showModal = false"
                        >&times;</span
                      >
                    </button>
                  </div>
                  <div class="modal-body">
                    <p>{{ message }}</p>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="showModal = false"
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<style>
.test {
  padding: 10px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.box {
  padding: 10px;
  background: rgb(247, 247, 247);
  height: 100vh;
  overflow: scroll;
}

.button-h {
  border-radius: 0px;
  width: 100%;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>

<script>
// @ is an alias to /src
import Vue from "vue";
import loading from "vuejs-loading-screen";

Vue.use(loading);

import axios from "axios";

export default {
  name: "Test",
  data() {
    return {
      has_head_ache: false,
      is_female: false,
      contact_with_confirmed: false,
      has_been_abroad: false,
      is_male: false,
      has_fever: false,
      has_cough: false,
      above_60_yes: false,
      above_60_no: false,
      has_shortness_of_breath: false,
      message: null,
      showModal: false,
    };
  },
  methods: {
    fetchAPI() {
      var self = this;
      const options = {
        method: "POST",
        url: "https://coronavirus-symptoms-predictor1.p.rapidapi.com/",
        params: {
          has_head_ache: this.num(this.has_head_ache),
          is_female: this.num(this.is_female),
          contact_with_confirmed: this.num(this.contact_with_confirmed),
          has_been_abroad: this.num(this.has_been_abroad),
          is_male: this.num(this.is_male),
          has_fever: this.num(this.has_fever),
          has_cough: this.num(this.has_cough),
          above_60_yes: this.num(this.above_60_yes),
          above_60_no: this.num(this.above_60_no),
          has_shortness_of_breath: this.num(this.has_shortness_of_breath),
        },
        headers: {
          "x-rapidapi-key":
            "e19ac64f04mshf01696ca91c0956p127440jsne7049df43764",
          "x-rapidapi-host": "coronavirus-symptoms-predictor1.p.rapidapi.com",
        },
      };
      self.$isLoading(true);
      axios
        .request(options)
        .then(function (response) {
          // this.data = response.data
          console.log(response.data);
          if (response.data["has_covid_symptoms"] == 1) {
            // console.log("He/ She has corona symptoms")
            self.message = "You have covid symptoms";
          } else {
            // console.log("Be happy!");
            self.message = "You dont have covid symptoms";
          }
          self.$isLoading(false);
          self.showModal = true;
        })
        .catch(function (error) {
          console.error(error);
        });
      //   this.$isLoading(false)
    },
    num(prop) {
      return Number(prop);
    },
  },
};
</script>
